
export const dateRegex = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/;
export const referralOptions = [
  "Web Search",
  "Social Media",
  "Referral",
  "Advertisement",
];

export const yearsOptions = [
  "2024","2025","2026","2027","2028","2029","Not listed"
];

export const sizeOptions = [
  "000", "00", "0", "2","4","6","8","10","12","14","16","18","20","22","24","26","28+"
];
export const toastConfigInitState = {
  show: false,
  message: "",
};

export const INPUT_NAME_MAP = {
  firstName: "firstName",
  lastName: "lastName",
  zipCode: "zipCode",
  dressSize: "dressSize",
  email: "email",
  highSchool: "highSchool",
  phoneNumber: "phoneNumber",
  referral: "referral",
  graduationYear: "graduationYear",
  eventDate: "eventDate",
};

export const formTypesKeys ={
  prom: "prom",
  mob: "mob",
  bridal:"bridal",
  bridesmaids:"bridesmaids",
  homecoming: "homecoming",
  quince: "quince",
  pageant: "pageant",
  guest:"guest",
  other: "other"
}


export const formTypes ={
  [formTypesKeys.prom]: "Prom",
  [formTypesKeys.mob]: "Parent of the Bride/Groom",
  [formTypesKeys.bridal]:"Bridal",
  [formTypesKeys.bridesmaids]:"Bridesmaids",
  [formTypesKeys.homecoming]: "Homecoming",
  [formTypesKeys.quince]: "Quince",
  [formTypesKeys.pageant]: "Pageant",
  [formTypesKeys.guest]:"Wedding Guest",
  [formTypesKeys.other]: "Other Social Occasion"
}