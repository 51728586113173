import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useController } from "react-hook-form";

export default function FormGroupSelect({
  label = "",
  type = "text",
  placeholder = "",
  inputName = "",
  successMessage = null,
  errors = {},
  rules = {},
  control = () => null,
  defaultValue = null,
  options = [],
}) {
  const {
    field: { onChange, onBlur, name, value, ref },
    fieldState: { invalid, isTouched, isDirty },
    formState: { touchedFields, dirtyFields },
  } = useController({
    name: inputName,
    control,
    rules,
    defaultValue,
  });

  return (
    <Form.Group controlId={`controlIdName${name}`}>
      <Form.Label>{label}</Form.Label>
      <InputGroup hasValidation>
        <Form.Select
          onChange={onChange}
          onBlur={onBlur}
          value={value || ""}
          ref={ref}
          type={type}
          placeholder={placeholder}
          name={name}
          isInvalid={!!errors[name]}
        >
          {options.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </Form.Select>

        <Form.Control.Feedback>{successMessage}</Form.Control.Feedback>
        {/*Spacer to remove the jank*/}
        {!errors[name]?.message && (
          <div style={{ height: "25px", width: "100%" }} />
        )}
        <Form.Control.Feedback type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
}
