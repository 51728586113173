import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useController } from "react-hook-form";
import Row from "react-bootstrap/Row";

export default function FormGroupInput({
  label = "",
  type = "text",
  placeholder = "",
  inputName = "",
  successMessage = null,
  errors = {},
  rules = {},
  control = () => null,
  defaultValue = null,
  isInputValid = () => true,
}) {
  const {
    field: { onChange, onBlur, name, value, ref },
  } = useController({
    name: inputName,
    control,
    rules,
    defaultValue,
  });

  return (
    <Form.Group controlId={`controlIdName${name}`} as={Row}>
      <Form.Label>{label}</Form.Label>
      <InputGroup hasValidation>
        <Form.Control
          style={{
            borderTopRightRadius: "0.375rem",
            borderBottomRightRadius: "0.375rem",
          }}
          onChange={(e) => {
            if (isInputValid(e)) {
              onChange(e);
            }
          }}
          onBlur={onBlur}
          value={value || ""}
          ref={ref}
          type={type}
          placeholder={placeholder}
          name={name}
          isInvalid={!!errors[name]}
        />
        <Form.Control.Feedback>{successMessage}</Form.Control.Feedback>
        {/*Spacer to remove the jank*/}
        {!errors[name]?.message && (
          <div style={{ height: "25px", width: "100%" }} />
        )}
        <Form.Control.Feedback type="invalid">
          {errors[name]?.message}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
}
