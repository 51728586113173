import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Layout from "../../components/Layout";
import { formTypes, formTypesKeys } from "../../lib/constants";

export default function HomePage() {
  return (
    <Layout gap={3}>
      <h4 className="text-center">
        Alexandra's Too Appointment Questionnaire
      </h4>
      <p className="text-center">
        This questionnaire allows our staff to take the information needed to
        help you through your appointment. None of this information is shared
        with anyone outside our internal checkout system and your stylist. Begin
        by selecting what type of dress you are interested in.
      </p>
      {Object.keys(formTypes).map((key) => {
        let to = `/intake-form/type/other/${key}`;
        if(key === formTypesKeys.prom){
          to = `/intake-form/type/prom`;
        }
        return <Link to={to}>
          <Button style={{
            width: "200px"
          }} variant={"outline-dark"} size={"sm"}>{formTypes[key]}</Button>
        </Link>;
      })}
    </Layout>
  );
}
