import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Toast from "react-bootstrap/Toast";
import Stack from "react-bootstrap/Stack";
import Form from "react-bootstrap/Form";
import FormGroupInput from "../../components/FormGroupInput";
import { useForm } from "react-hook-form";
import FormGroupSelect from "../../components/FormGroupSelect";
import { emailRegex } from "../../lib/utils";
import { createIntakeForm } from "../../services/intakeFormApi";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import {
  dateRegex,
  formTypes,
  INPUT_NAME_MAP,
  referralOptions,
  sizeOptions,
  toastConfigInitState,
} from "../../lib/constants";

export function IntakeFormOther() {
  const navigate = useNavigate();
  const { type } = useParams();
  const [currentStep, setCurrentSteps] = useState(1);
  const [loading, setLoading] = useState(false);
  const [toastConfig, setToastConfig] = useState(toastConfigInitState);
  const hideToast = () => setToastConfig(toastConfigInitState);
  const showErrorToast = (
    message = "Please double-check all entries to ensure they are correct.",
  ) => {
    setToastConfig({ show: true, message });
  };

  const {
    control,
    getFieldState,
    getValues,
    formState: { errors, dirtyFields, touchedFields },
  } = useForm({
    mode: "onBlur",
  });

  const onSubmit = async () => {
    setLoading(true);
    try {
      if (isStepValid(1) && isStepValid(2) && isStepValid(3)) {
        const values = getValues();
        await createIntakeForm({ ...values, formType: type });
        navigate(`/success/${type}`);
      } else {
        showErrorToast();
      }
    } catch (e) {
      showErrorToast(
        "Apologies for the error. Please try again or consult a stylist for further assistance.",
      );
    }
    setLoading(false);
  };

  const onClickNext = () => {
    if (currentStep === 2) {
      onSubmit();
    } else {
      if (isStepValid(currentStep)) {
        setCurrentSteps(currentStep + 1);
      } else {
        showErrorToast();
      }
    }
  };

  const isStepFieldsValid = (fields) => {
    for (let name of fields) {
      let state = getFieldState(name);
      if (state.invalid || !state.isTouched) {
        return false;
      }
    }
    return true;
  };

  const isStepValid = (stepNumber) => {
    let fields = [];
    switch (stepNumber) {
      case 1:
        fields = [
          INPUT_NAME_MAP.firstName,
          INPUT_NAME_MAP.lastName,
          INPUT_NAME_MAP.phoneNumber,
          INPUT_NAME_MAP.email,
        ];
        break;
      case 2:
        fields = [
          INPUT_NAME_MAP.zipCode,
          INPUT_NAME_MAP.dressSize,
          INPUT_NAME_MAP.referral,
          INPUT_NAME_MAP.eventDate,
        ];
        break;
    }
    return isStepFieldsValid(fields);
  };

  const goToStep = (step) => {
    setCurrentSteps(step);
  };

  return (
    <Layout showLogo={false}>
      <Form as={Row}>
        <h4 className={"text-left"}>{formTypes[type]} Questionnaire</h4>
        <Stack
          direction="horizontal"
          gap={3}
          className={"justify-content-center mt-2 mb-3"}
        >
          <div
            onClick={() => goToStep(1)}
            className={`tab-bottom-line tab-bottom-line-complete`}
          >
            1. Personal Details
          </div>
          <div
            onClick={() => goToStep(2)}
            className={`tab-bottom-line ${
              currentStep > 1 ? "tab-bottom-line-complete" : ""
            }`}
          >
            2. Final Details
          </div>
        </Stack>
        {currentStep === 1 && (
          <Stack className={""}>
            <FormGroupInput
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "First name is required",
                },
              }}
              label={"First name"}
              placeholder={"Allie"}
              inputName={INPUT_NAME_MAP.firstName}
              errors={errors}
            />
            <FormGroupInput
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Last name is required",
                },
              }}
              label={"Last name"}
              placeholder={"Smith"}
              inputName={INPUT_NAME_MAP.lastName}
              errors={errors}
            />
            <FormGroupInput
              type={"tel"}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Phone number is required",
                },
              }}
              label={"Phone number"}
              placeholder={"508-123-4567"}
              inputName={INPUT_NAME_MAP.phoneNumber}
              errors={errors}
            />
            <FormGroupInput
              type={"email"}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Email is required",
                },
                pattern: {
                  value: emailRegex,
                  message: "Invalid email format",
                },
              }}
              label={"Email address"}
              placeholder={"prom@alexandrasbride.com"}
              inputName={INPUT_NAME_MAP.email}
              errors={errors}
            />
          </Stack>
        )}
        {currentStep === 2 && (
          <Stack>
            <FormGroupInput
              type={"tel"}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Zip code is required",
                },
                maxLength: {
                  value: 5,
                  message: "Zip code cannot be more than 5 digits",
                },
                minLength: {
                  value: 5,
                  message: "Zip code cannot be less than 5 digits",
                },
                pattern: {
                  value: /^[0-9]*$/,
                  message: "Zip code should be numbers only",
                },
              }}
              label={"Zip code"}
              placeholder={"02721"}
              inputName={INPUT_NAME_MAP.zipCode}
              errors={errors}
            />
            <FormGroupInput
              isInputValid={(e) => {
                return true;
              }}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Event date is required",
                },
                pattern: {
                  value: dateRegex,
                  message: "Event date should be in format DD/MM/YYYY",
                },
              }}
              label={"Event date"}
              placeholder={"06/02/2024"}
              inputName={INPUT_NAME_MAP.eventDate}
              errors={errors}
            />
            <FormGroupSelect
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Jean size is required",
                },
              }}
              label={"Jean size"}
              inputName={INPUT_NAME_MAP.dressSize}
              errors={errors}
              options={["Select option", ...sizeOptions]}
            />
            <FormGroupSelect
              control={control}
              rules={{
                required: {
                  value: true,
                  message: "Referral type is required",
                },
              }}
              label={"Referral type"}
              inputName={INPUT_NAME_MAP.referral}
              errors={errors}
              options={["Select option", ...referralOptions]}
            />
          </Stack>
        )}
        <Stack className="mt-3 justify-content-center">
          <Button
            disabled={loading}
            variant="outline-primary"
            type="submit"
            onClick={onClickNext}
          >
            {currentStep === 2 ? "Finish" : "Next"}
          </Button>
        </Stack>
      </Form>
      <Toast
        bg={"danger"}
        className={"position-absolute top-0 mt-2 text-white z-index-1000"}
        show={toastConfig.show}
        onClose={hideToast}
        autohide={true}
        onClick={hideToast}
      >
        <Toast.Body>{toastConfig.message}</Toast.Body>
      </Toast>
    </Layout>
  );
}
