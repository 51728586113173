import axios from "axios";
import config from "./config";

const api = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  baseURL: config.apiUrl,
  timeout: 30000,
});

export default api;
