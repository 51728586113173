import React from "react";
import "./index.scss";
import "./custom.scss";
import "animate.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import NotFoundPage from "./pages/not-found/NotFoundPage";
import SuccessPage from "./pages/success/SuccessPage";
import { IntakeFormProm } from "./pages/intake-form/IntakeFormProm";
import { IntakeFormOther } from "./pages/intake-form/IntakeFormOther";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/intake-form/type/prom" element={<IntakeFormProm />} />
      <Route path="/intake-form/type/other/:type" element={<IntakeFormOther />} />
      <Route path="/success/:type" element={<SuccessPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </BrowserRouter>
);
