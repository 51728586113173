const defaults = {};
const environmentConfigs = {
  local: {
    apiUrl: "http://localhost:4444",
  },
  staging: {
    apiUrl: "https://walkin-forms-api-dev.alexandras.sprouttechlab.com",
  },
  production: {
    apiUrl: "https://walkin-forms-api.alexandras.sprouttechlab.com",
  },
};

let environmentConfig;
const hostnameParts = window.location.host.split(".");
switch (hostnameParts[0]) {
  case "staging":
    environmentConfig = environmentConfigs.staging;
    break;
  case "www":
  case "alexandras":
    environmentConfig = environmentConfigs.production;
    break;
  default:
    environmentConfig = environmentConfigs.local;
    break;
}

const mergedConfig = {
  ...defaults,
  ...environmentConfig,
};

export default mergedConfig;
