import api from "../lib/api";

export async function createIntakeForm(params) {
  try {
    const { data } = await api.post("/intake-form", params);
  } catch (e) {
    console.error("error", e);
    throw new Error("Something went wrong");
  }
}
