import React from "react";
import CloseButton from "react-bootstrap/CloseButton";
import Container from "react-bootstrap/Container";
import Stack from "react-bootstrap/Stack";
import { ReactComponent as Logo } from "../assets/alexandras-boutique-logo.svg";

export default function Layout(props) {
  const { showClose = true, showLogo = true, gap = 0 } = props;
  return (
    <Container className={"mt-5 align-items-center"}>
      {showClose && (
        <CloseButton
          onClick={() => (window.location.href = "/")}
          style={{ position: "absolute", right: 5, top: 5 }}
        />
      )}
      <Stack
        gap={gap}
        className="col-xl-5 mx-auto align-items-center justify-content-center"
      >
        {showLogo && <Logo />}
        {props.children}
      </Stack>
    </Container>
  );
}
