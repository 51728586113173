import React from "react";
import Stack from "react-bootstrap/Stack";
import { ReactComponent as Logo } from "../../assets/alexandras-boutique-logo.svg";
import { Link } from "react-router-dom";
export default function SuccessPage() {
  return (
    <Stack
      gap={2}
      className="col-md-5 mx-auto mt-5 align-items-center justify-content-center animate__animated animate__slideInDown"
    >
      <Logo />
      <div>
        <h4 className={"text-center mt-5"}>
          Thank you for choosing us! We are excited to help you achieve your
          desired look.
        </h4>
        <h4 className={"text-center mt-3"}>
          Our stylists will be with you shortly.
        </h4>
      </div>
      <Link className={"text-center mt-5"} to={"/"}>
        Go Home
      </Link>
    </Stack>
  );
}
