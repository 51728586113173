import React from "react";
import { Link } from "react-router-dom";
import Stack from "react-bootstrap/Stack";

export default function NotFoundPage() {
  return (
    <Stack gap={2} className="col-md-5 mx-auto mt-5">
      <div className="d-flex justify-content-center">
        <div className="">Page not found.</div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="">
          <Link to={"/"}>Go home</Link>
        </div>
      </div>
    </Stack>
  );
}
